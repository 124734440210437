<template>
  <div class="container-fluid mt-6">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <div class="row">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SUPPLIERS)"
          :to="{ name: 'List Suppliers' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa-regular fa-2x fa-boxes-stacked"></i>
            </span>
            <small>{{ $t("COMMON.SUPPLIERS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PURCHASES_ORDERS)"
          :to="{ name: 'List PurchasesOrders' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa-regular fa-2x fa-bag-shopping"></i>
            </span>
            <small>{{ $t("COMMON.PURCHASES_ORDERS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_PURCHASES_INVOICES)
          "
          :to="{ name: 'List PurchasesInvoices' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa-light fa-2x fa-file-invoice"></i>
            </span>
            <small>{{ $t("COMMON.PURCHASES_INVOICES") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_PURCHASES_PAYMENTS)
          "
          :to="{ name: 'List PurchasesPayments' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa-regular fa-2x fa-coins"></i>
            </span>
            <small>{{ $t("COMMON.PURCHASES_PAYMENTS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_PURCHASES_DELIVERIES)
          "
          :to="{ name: 'List PurchasesDeliveries' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa-regular fa-2x fa-truck"></i>
            </span>
            <small>{{ $t("COMMON.PURCHASES_DELIVERIES") }}</small>
          </a>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
